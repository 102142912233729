import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  titleOverride,
  image: imageProp,
  pathname,
  article
}) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          defaultImage: image
          siteUrl: url
          twitter
          fbAppId
        }
      }
    }
  `);

  const siteUrl = site.siteMetadata.siteUrl;

  const metaDescription = description || site.siteMetadata.description;
  const image = `${siteUrl}${imageProp || site.siteMetadata.defaultImage}`;
  const url = `${siteUrl}${pathname || "/"}`;
  const usedTitle = titleOverride ?? title;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: usedTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: `${article ? "article" : "website"}`
        },
        {
          property: `og:url`,
          content: url
        },
        {
          property: `og:image`,
          content: image
        },
        {
          property: `fb:app_id`,
          content: site.siteMetadata.fbAppId
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.twitter
        },
        {
          name: `twitter:title`,
          content: usedTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:image`,
          content: image
        }
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
      title={titleOverride ?? title}
      titleTemplate={titleOverride ?? `%s | ${site.siteMetadata.title}`}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: []
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
  titleOverride: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool
};

export default SEO;
