import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";

import "@fortawesome/fontawesome-svg-core/styles.css";

library.add(faEnvelope, faCheck, faTimes);

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    offset: 220
  });
}

function Layout({ children }) {
  return (
    <div
      id="home"
      className="flex flex-col font-sans min-h-screen text-gray-900"
    >
      <Header />
      {/* <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full bg-gray-100"> */}
      <main>{children}</main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
