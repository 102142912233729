import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer className="bg-gray-900">
      <nav className="sm:flex sm:justify-between text-center max-w-4xl mx-auto p-4 md:p-8 text-sm">
        <p className="text-white font-bold">Roger Chi Consulting</p>

        <p className="mt-2 sm:mt-0">
          <Link className="no-underline text-gray-200" to="/terms">
            Terms &amp; Conditions
          </Link>
          <Link className="ml-4 no-underline text-gray-200" to="/privacy">
            Privacy Policy
          </Link>
        </p>
      </nav>
    </footer>
  );
};

export default Footer;
