import { graphql, useStaticQuery, Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = ({ numSelected = 0 }) => {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header className="sticky top-0 bg-gray-100 z-10 shadow-md">
      <div className="flex flex-col sm:flex-row items-center content-center justify-between max-w-4xl mx-auto p-6 h-auto">
        <div className="flex flex-wrap w-full sm:w-auto sm:whitespace-no-wrap items-center justify-between">
          <Link
            className="flex items-center no-underline text-gray-800"
            to="/#home"
          >
            <span className="font-bold text-xl tracking-tight">
              {site.siteMetadata.title}
            </span>
          </Link>
          <div className="block flex sm:hidden">
            <Link
              aria-label="Contact"
              className="sm:inline-block no-underline text-gray-800"
              to={`/#contact`}
            >
              <span className="fa-layers fa-lg fa-fw mt-1">
                <FontAwesomeIcon
                  className="text-gray-600 fill-current"
                  icon={["fas", "envelope"]}
                />
                {numSelected > 0 && (
                  <span className="fa-layers-counter fa-lg bg-red-600">
                    {numSelected}
                  </span>
                )}
              </span>
            </Link>
            <button
              className="block border border-white flex items-center ml-2 px-2 py-2 rounded text-gray-800"
              onClick={() => toggleExpansion(!isExpanded)}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
        </div>
        <nav
          className={`${
            isExpanded ? `h-40` : `h-0`
          } transition-height duration-300 sm:h-auto sm:block sm:flex sm:items-center w-full sm:justify-end bg-gray-100 overflow-hidden sm:overflow-auto`}
        >
          {[
            {
              route: `/#home`,
              title: `Home`
            },
            {
              route: `/#skills`,
              title: `Skills`
            },
            {
              route: `/#about`,
              title: `About`
            }
            // {
            //   route: `/blog`,
            //   title: `Blog`
            // }
            // {
            //   route: `/contact`,
            //   title: `Contact`
            // }
          ].map(link => (
            <Link
              className="block sm:inline-block mt-4 sm:mt-0 sm:ml-6 no-underline text-gray-800"
              key={link.title}
              to={link.route}
              onClick={() => toggleExpansion(!isExpanded)}
            >
              {link.title}
            </Link>
          ))}
          <Link
            className="block whitespace-no-wrap sm:inline-block mt-4 sm:mt-0 sm:ml-6 no-underline text-gray-800"
            to={`/#contact`}
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <span>Contact</span>
          </Link>
          <Link
            className="hidden sm:block whitespace-no-wrap sm:inline-block mt-4 sm:mt-0 sm:ml-6 no-underline text-gray-800"
            to={`/#contact`}
          >
            <span className="fa-layers fa-lg fa-fw -mb-1">
              <FontAwesomeIcon
                className="text-gray-600 fill-current"
                icon={["fas", "envelope"]}
              />
              {numSelected > 0 && (
                <span className="fa-layers-counter fa-lg bg-red-600">
                  {numSelected}
                </span>
              )}
            </span>
          </Link>
        </nav>
      </div>
    </header>
  );
};

Header.propTypes = {
  numSelected: PropTypes.number
};

export default Header;
